import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

class FancyBox {
  constructor() {
    this.init();
  }

  init() {
    Fancybox.bind("[data-fancybox]", {
      // Options will go here
    });
  }
}

export default FancyBox;