import $ from 'jquery';

export default class Menu {
  constructor() {
    this.menu = $('.header-nav');
    this.menuToggle = $('.header__wrapper__menu__toggler');
    this.events();
    }

  events(){
    this.menuToggle.on('click', this.toggleMenu.bind(this));
  }

  toggleMenu() {
    this.menu.toggleClass('open');
    this.menuToggle.toggleClass('open');
  }
}