import $ from 'jquery';

export default class Countdown {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      const hoursElement = document.getElementById('hours');
      const minutesElement = document.getElementById('minutes');
      const secondsElement = document.getElementById('seconds');

      // Tempo total de 15 horas em milissegundos
      let countdownDuration = 15 * 60 * 60 * 1000; // 15 horas

      let targetTime = Date.now() + countdownDuration; // Define o tempo de término

      const updateCountdown = () => {
        const now = Date.now();
        let distance = targetTime - now;

        if (distance < 0) {
          // Se o tempo acabar, reinicia o contador
          targetTime = Date.now() + countdownDuration;
          distance = countdownDuration; // Reseta a distância para 15 horas
        }

        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        hoursElement.textContent = hours < 10 ? '0' + hours : hours;
        minutesElement.textContent = minutes < 10 ? '0' + minutes : minutes;
        secondsElement.textContent = seconds < 10 ? '0' + seconds : seconds;
      };

      const interval = setInterval(updateCountdown, 1000);
      updateCountdown();
    });
  }
}

