import $ from "jquery";

export default class Login {
  constructor() {
    if (document.getElementById("login-form")) {
      $("#login-form").on("submit", function (e) {
        e.preventDefault();

        var form = $(this);
        var responseDiv = $("#login-response");

        responseDiv.html(`
          <div class="loading">

          </div>
        `);

        $.ajax({
          type: "POST",
          url: abiq.ajax_url,
          data: {
            action: "custom_login",
            log: form.find("#username").val(),
            pwd: form.find("#password").val(),
            login_security: form.find("#login-security").val(),
          },
          success: function (response) {
            if (response.success) {
                window.location.href = abiq.root_url + '/area-do-associado';
            } else {
              var errors = response.data;
              var errorHtml = '<div class="errors">';
              for (var i = 0; i < errors.length; i++) {
                errorHtml += "<p>" + errors[i] + "</p>";
              }
              errorHtml += "</div>";
              responseDiv.html(errorHtml);
            }
          },
          error: function (xhr, status, error) {
            responseDiv.html(
              '<div class="errors">Ocorreu um erro inesperado durante o login, por favor tente mais tarde</div>'
            );
          },
        });
      });
    }
  }
}
